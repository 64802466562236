
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import CurrencySwitcherTooltip from '@/modules/common/components/currency-switcher-tooltip.vue';
import type UserService from '@/modules/user/user.service';

import { SettingsGeneralService } from '../../settings-general.service';

@Component({
    components: {
        CustomSelect,
        StyledSearchbar,
        CurrencySwitcherTooltip,
    },
})
export default class CurrencySection extends Vue {
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;
    @inject(KEY.UserService) private userService!: UserService;

    isStatusError = false;
    currentCurrency: string | null = null;
    status = '';
    query = '';

    beforeMount() {
        this.currentCurrency = this.settingsGeneralService.displayCurrency;
    }

    get currencyItems() {
        const { currencies } = this.userService;

        const currencyList = Object
            .entries(currencies)
            .map(([_, curr]) => ({
                name: `${curr.code}`,
                value: curr.code,
            }))
            .filter(
                item => item.name
                    .toLowerCase()
                    .includes(this.query.toLowerCase()),
            );

        currencyList.splice(0, 0, {
            name: this.$tc('filters.byPos'),
            value: null as unknown as string,
        });

        return currencyList;
    }

    get isCurrencyLoading() {
        return this.settingsGeneralService.isCurrenciesLoading;
    }

    async saveCurrency() {
        this.status = '';
        this.isStatusError = false;

        try {
            await this.settingsGeneralService
                .updateDisplayCurrency(this.currentCurrency);

            if (this.currentCurrency !== null) {
                this.status = this.$tc('saved');
            } else {
                this.status = this.$tc('settings.scan.savedSelectedPos');
            }
        } catch (err) {
            this.status = this.$tc('somethingWrong');
            this.isStatusError = true;
        }
    }

    resetCurrency() {
        this.currentCurrency = null;
        this.saveCurrency();
    }

    updateQuery(newQuery: string) {
        this.query = newQuery;
    }
}
